import logoMobile from "./logoMobile.svg";
import "./App.css";
import useWindowDimensions from "./Utils/WindowSize";

function App() {
  const { width } = useWindowDimensions();
  return (
    <div className="App">
      {width > 768 ? (
        <img src={logoMobile} alt="" />
      ) : (
        <img src={logoMobile} className="iconMobile" alt="" />
      )}
    </div>
  );
}

export default App;
